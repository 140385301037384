<template>
    <div>
        <TableList
            :form_data_seo.sync="form_data_seo"
            :columns="columns"
            :get_table_list="this.get_table_list"
            :slot_table_list="['operation', 'work_time', 'create_user']"
            ref="list"

        >
            <template slot="left_btn">
                <a-button
                    class="float_left"
                    v-auth="'change'"
                    @click="add()"
                    type="primary"
                    >添加考勤规则</a-button
                >
            </template>
            <template slot="work_time" slot-scope="data">
                <span v-for="(item, index) in data.text" :key="index">{{
                    item
                }}</span>
            </template>
            <template slot="create_user" slot-scope="data">
                <span>{{
                    data.text.username ? data.text.username : "无"
                }}</span>
            </template>

            <template slot="operation" slot-scope="data">
                <a @click="edit(data.record)">编辑</a>
            </template>
        </TableList>
    </div>
</template>

<script>
import EditPop from "@/components/EditPop";
import Form from "@/components/Form";
import { attendance } from "@/api/personnel";
import TableList from "@/components/TableList";
const columns = [
    {
        title: "考勤规则名称",
        dataIndex: "rule_name",
    },
    {
        title: "考勤人数",
        dataIndex: "rule_user_num",
    },
    {
        title: "上下班时间",
        dataIndex: "work_time",
        scopedSlots: {
            customRender: "work_time"
        }
    },
    {
        title: "备注",
        dataIndex: "remark",
    },
    {
        title: "创建人员",
        dataIndex: "create_user",
        scopedSlots: {
            customRender: "create_user"
        }
    },
    {
        title: "操作",
        dataIndex: "operation",
        scopedSlots: {
            customRender: "operation"
        }
    }
];


export default {
    name: "Index",
    components: {
        EditPop,
        TableList,
        Form
    },
    data() {
        return {
            get_table_list: attendance,
            form: this.$form.createForm(this, {
                name: "advanced_search"
            }),
            columns,
            form_data_seo: {
                list: [
                    {
                        type: "text",
                        name: "keyword",
                        title: "考勤规则名称",
                        mode: "default",
                        options: {}
                    }
                ],
                show_submit_btn: true,
                submit_name: "查询",
                layout: "inline"
            }
        };
    },
created() {
		// 为页面添加缓存
		// this.$keep_route.add(this.$options.name);
	},
    methods: {
        add() {
            this.$router.push("/attendance/index_config");
        },
        edit(key) {
            this.$router.push("/attendance/index_config?id=" + key.id);
        }
    }
};
</script>

<style lang="less">
@import url("../../assets/less/app.less");
</style>